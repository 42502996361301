<template>
  <div class="p-b-30">
    <DetailPure :detail="detail" />
  </div>
</template>

<script>
import DetailPure from "@/components/detail/detailPure_p.vue";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["isMobile", "trees", "articles"]),
    detail() {
      const index = this.articles.findIndex(
        (article) => article.id === this.$route.query.id
      );
      if (index > -1) {
        return this.articles[index];
      }
      return null;
    }
  },
  components: {
    DetailPure
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped></style>
