<template>
  <div class="page">
    <IndexM v-if="isMobile" />
    <IndexP v-else />
  </div>
</template>

<script>
import IndexM from "./index_m.vue";
import IndexP from "./index_p.vue";
import { mapState } from "vuex";
export default {
  components: {
    IndexM,
    IndexP
  },
  computed: {
    ...mapState(["isMobile"])
  }
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100%;
}
</style>
